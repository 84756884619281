.back {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 6px;
  color: white;
  font-weight: 700;
  font-size: 16px;
  position: absolute;
  z-index: 2;
  top: 60px;
}
