.resetpass-wrapper {
  width: 100%;
}

.reset-pass {
  width: 100%;
  height: 100%;
}

.reset-email {
  position: relative;
  z-index: 1;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 56px;
}

.reset-title {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 24px;
  color: #fff;
}

.reset-title > h3 {
  font-size: 32px;
  line-height: 40%;
  font-weight: bold;
}

.reset-title > p {
  font-size: 24px;
  font-weight: 400;
  text-align: center;
}

.reset-flex {
  display: flex;
  justify-content: center;
  width: 100%;
  gap: 100px;
}

.reset-left {
  flex: 1;
}

.reset-account {
  flex: 3;
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.reset-account h6 {
  font-size: 20px;
  color: #fff;
  font-weight: bold;
}

/* Error için gerekli style */
.reset-account.error label.verificationcode {
  background: #fff;
  opacity: 1;
}

.reset-account label.verificationcode > h6 {
  color: #ee6243;
}

.reset-account.error label.verificationcode svg path {
  fill: #ee6243;
}

.reset-account.error label.verificationcode input {
  border: 2px solid #ee6243;
  color: #ee6243;
  border-radius: 4px;
  background: url("../../assets/images/svg/error.svg");
  background-repeat: no-repeat;
  background-position: 98% center;
}

.wrong-verificationcode,
.resetcode-expired {
  display: none;
}

.wrong-verificationcode.error,
.resetcode-expired.error {
  display: block;
  padding-left: 15px;
  color: #fff !important;
  font-size: 12px;
}

.reset-account.error label.password {
  background: #fff;
  opacity: 1;
}

.reset-account label.password > h6 {
  color: #ee6243;
}

.reset-account.error label.password svg path {
  fill: #ee6243;
}

.reset-account.error label.password input {
  border: 2px solid #ee6243;
  color: #ee6243;
  border-radius: 4px;
  background: url("../../assets/images/svg/error.svg");
  background-repeat: no-repeat;
  background-position: 98% center;
}

/* Error için gerekli style */

.reset-accept > button {
  background: rgba(17, 18, 21, 0.5);
  width: 254px;
  height: 44px;
  color: #eaeaea;
  font-size: 16px;
  font-weight: bold;
  border-radius: 4px;
  transition: 0.4s ease-in-out all;
}

.reset-accept > button:hover {
  background: rgba(17, 18, 21, 0.5);
  filter: drop-shadow(0px 1px rgba(0, 0, 0, 0.3));
}

@media only screen and (max-width: 780px) {
  .reset-flex {
    gap: 20px;
  }

  .reset-left {
    justify-content: flex-start;
  }
}
button {
  background: none;
  outline: none;
  border: none;
  cursor: pointer;
}

button.disabled {
  width: 240px;
  height: 44px;
  padding: 10px 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(255, 255, 255, 0.12);
  color: #fff;
  font-size: 16px;
  font-weight: 700;
  border-radius: 4px;
  pointer-events: none;
  transition: 1s ease-in-out;
}

button.continue {
  width: 240px;
  height: 44px;
  padding: 10px 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(17, 18, 21, 0.5);
  color: #fff;
  font-size: 16px;
  font-weight: 700;
  border-radius: 4px;
  transition: 1s ease-in-out;
}
