.forgotpass-wrapper {
  width: 100%;
}

.forgot-pass {
  width: 100%;
  height: 100%;
}

.forgot-email {
  position: relative;
  z-index: 1;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 56px;
}

.forgot-title {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 24px;
  color: #fff;
}

.forgot-title > h3 {
  font-size: 32px;
  line-height: 40%;
  font-weight: bold;
}

.forgot-title > p {
  font-size: 24px;
  font-weight: 400;
  text-align: center;
}

.forgot-flex {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  gap: 100px;
}

.forgot-left {
  flex: 1;
}

.forgot-account {
  flex: 3;
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.forgot-account h6 {
  font-size: 20px;
  color: #fff;
  font-weight: bold;
}

.forgot-accept > button {
  background: rgba(17, 18, 21, 0.5);
  width: 254px;
  height: 44px;
  color: #eaeaea;
  font-size: 16px;
  font-weight: bold;
  border-radius: 4px;
  transition: 0.4s ease-in-out all;
}

/* Error için gerekli style */
.forgot-account.error label.username {
  background: #fff;
  opacity: 1;
}

.forgot-account label.username > h6 {
  color: #ee6243;
}

.forgot-account.error label.username svg path {
  fill: #ee6243;
}

.forgot-account.error label.username input {
  color: #ee6243;
  border: 2px solid #ee6243;
  border-radius: 4px;
  background: url("../../assets/images/svg/error.svg");
  background-repeat: no-repeat;
  background-position: 98% center;
}

.wrong-username {
  display: none;
}

.wrong-username.error {
  display: block;
  padding-left: 15px;
  color: #fff !important;
  font-size: 12px;
}

/* Error için gerekli style */

.forgot-accept > button:hover {
  background: rgba(17, 18, 21, 0.5);
  filter: drop-shadow(0px 1px rgba(0, 0, 0, 0.3));
}

@media only screen and (max-width: 780px) {
  .forgot-flex {
    gap: 20px;
  }

  .forgot-left {
    justify-content: flex-start;
  }
}
