.privacy {
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
}

.privacy .privacy-content {
  width: 100%;
  position: relative;
  z-index: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  gap: 56px;
  background: rgba(255, 255, 255, 0.1);
  backdrop-filter: blur(25px);
  box-shadow: 0px 4px 20px 0 rgba(0, 0, 0, 0.2);
  padding: 25px;
}
