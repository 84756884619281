
.denizbank-wrapper{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
}

.denizbank-account{
    width: 480px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    color: #fff;

}

.select-gender{
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 20px;
    align-items: flex-start;
    margin-top: 25px;
}

.select-gender h6{
    color: #fff;
}

.select-gender .genders{
    display: flex;
    gap: 20px;
    width: 100%;
}

.select-gender .gender-radio{
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 20px;
    
}

.select-gender .gender-radio h6{
    font-size: 16px;
    color: #fff;
}

.select-gender .gender-radio input[type="radio"]{
  appearance: none;
  position: relative;
  width: 16px;
  height: 16px;
  border: 1px solid #fff;
  border-radius: 50%;
  cursor: pointer;
}

.select-gender .gender-radio input[type="radio"]:checked::before{
    content: "";
    position: absolute;
    top: 50%;
    left: 50%;
    width: 8px;
    height: 8px;
    border-radius: 50%;
    transform: translate(-50%, -50%);
    background: rgba(255, 255, 255, 0.8);
}

.denizbank-account label{
    margin-top: 20px;
}

.denizbank-wrapper .login-accept.denizbank-btn{

    margin-top: 20px;
}