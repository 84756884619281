.metamask_wrapper {
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.metamask_container_reminder {
  width: 650px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
}

.metamask_container_reminder h1 {
  font-size: 32px !important;
  color: white !important;
  font-weight: 700 !important;
}

.metamask_container_reminder h2 {
  font-size: 24px !important;
  color: white !important;
  font-weight: 400 !important;
  margin: 20px 0 50px 0;
}

.metamask_container_reminder p {
  font-size: 16px;
  color: white;
  font-weight: 700;
  margin-top: 30px;
}

.metamask_checkbox {
  display: flex;
  gap: 15px;
  margin-top: 40px;
}

.metamask_checkbox label {
  font-size: 16px;
  font-weight: 700;
  color: white;
}

.metamask_checkbox input[type="checkbox"] {
  position: relative;
  border: 2px solid #ffffff;
  border-radius: 2px;
  background: none;
  cursor: pointer;
  line-height: 0;
  margin: 0 0.6em 0 0;
  outline: 0;
  padding: 0 !important;
  vertical-align: text-top;
  height: 20px;
  width: 20px;
  -webkit-appearance: none;
  opacity: 0.5;
}

.metamask_checkbox input[type="checkbox"]:hover {
  opacity: 1;
}

.metamask_checkbox input[type="checkbox"]:checked {
  background-color: black;
  opacity: 1;
}

.metamask_checkbox input[type="checkbox"]:before {
  content: "";
  position: absolute;
  right: 50%;
  top: 50%;
  width: 4px;
  height: 10px;
  border: solid #fff;
  border-width: 0 2px 2px 0;
  margin: -1px -1px 0 -1px;
  transform: rotate(45deg) translate(-50%, -50%);
  z-index: 2;
}

.metamask_container_reminder button {
  margin-top: 66px;
  padding: 10px 24px;
  border-radius: 4px;
  background-color: #11121580;
  font-size: 16px;
  font-weight: 700;
  border: none;
  outline: none;
  cursor: pointer;
  color: white;
}

.login-checkbox {
  display: flex;
  align-items: center;
  gap: 25px;
  margin-top: 30px;
}

.login-checkbox > input[type="checkbox"] {
  position: relative;
  appearance: none;
  width: 18px;
  height: 18px;
  border: 2px solid #fff;
  border-radius: 2px;
  cursor: pointer;
}

.login-checkbox > input[type="checkbox"]:checked {
  transition: 1s ease-in-out all;
}

.login-checkbox > input[type="checkbox"]:checked::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #fff;
}

.login-checkbox > input[type="checkbox"]:checked::before {
  content: "";
  position: absolute;
  z-index: 1;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: url("../../assets/img/check.png");
  background-position: center;
  background-repeat: no-repeat;
}

.login-checkbox > span {
  color: #fff;
  font-size: 16px;
  font-weight: 700;
}
