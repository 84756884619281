.auth-ingame {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 3;
  padding: 20px;
  background: linear-gradient(
    to left top,
    #0afff0 0%,
    #80b2ec 36.98%,
    #c565d5 60.42%,
    #f54fb3 100%
  );
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  border-radius: 5px;
  width: 820px;
  /* height: 350px; */
}

.auth-ingame .auth-ingame-close {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 20px;
}

.auth-ingame .auth-ingame-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.auth-ingame .auth-ingame-wrapper > h1 {
  font-size: 32px;
  color: #fff;
  text-align: center;
  margin-bottom: 20px;
}

.auth-ingame .auth-ingame-wrapper > p {
  font-size: 16px;
  color: #fff;
  text-align: center;
  font-weight: 600;
  margin-bottom: 45px;
}

.auth-ingame .auth-ingame-wrapper .auth-ingame-cards {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  gap: 25px;
  margin-top: auto;
  height: 100%;
}

.auth-ingame-card {
  box-shadow: rgba(75, 75, 75, 0.35) 0px 5px 6px;
  backdrop-filter: blur(2px);
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  transition: 0.4s ease-in-out all;
  z-index: 1;
  cursor: pointer;
  height: 130px;
  padding: 20px;
  margin-bottom: 20px;
  width: 200px;
}

.auth-ingame-card:hover {
  transform: scale(1.1);
  box-shadow: rgba(75, 75, 75, 0.35) 0px 5px 15px;
  background: #222;
}

.auth-ingame-card > h6 {
  font-size: 12px;
  color: #fff;
  margin-top: 6px;
  text-align: center;
}

.auth-ingame-card-image {
  width: 85px;
  height: 85px;
}

.ingame-login {
  display: flex;
  align-items: center;
  gap: 15px;
  width: 100%;
}

.ingame-login-label {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.ingame-login-label > h6 {
  color: #fff;
  margin-bottom: 16px;
  font-size: 16px;
}

.login-account > button {
  width: 250px;
  font-size: 14px;
  color: #fff;
  padding: 15px;
  align-self: center;
}

.login-account > button:hover {
  background: #11121580 !important;
  border-radius: 5px !important;
}

.auth-ingame-account {
  display: flex;
  gap: 15px;
  margin-top: 15px;
  color: #fff;
}

.auth-ingame-account > h6 {
  font-size: 14px;
  color: #fff;
}

.auth-ingame-account > button {
  font-size: 14px;
  color: #fff;
  padding: 5px 10px;
  align-self: center;
  transition: 0.5s ease-in-out all;
}

.auth-ingame-account > button:hover {
  color: rgba(255, 255, 255, 0.6);
  background: #111;
  padding: 5px 10px;
  border-radius: 4px;
}

.ingame-register-modal {
  display: flex;
  align-items: center;
  gap: 15px;
  width: 100%;
  margin-bottom: 25px;
}

.ingame-register-modal-label {
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 25px;
}

.ingame-register-modal-label > h6 {
  color: #fff;
  font-size: 16px;
}

.ingame-register-modal-label label > h6 {
  color: #fff;
}

.ingame-register-button > button {
  display: block;
  text-align: center;
  padding: 10px 20px;
  transition: 0.5s ease-in-out all;
  cursor: pointer;
  background: #11121580;
  border-radius: 5px !important;
  color: #fff;
}

.ingame-register-button > button.disable {
  display: block;
  text-align: center;
  padding: 10px 20px;
  transition: 0.5s ease-in-out all;
  cursor: pointer;
  background: #11121580;
  border-radius: 5px !important;
  color: #fff;
  opacity: 0.5;
  pointer-events: none;
}
