.login-email {
  position: relative;
  z-index: 1;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 56px;
}

.login-title {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 24px;
  color: #fff;
}

.login-title > h3 {
  font-size: 32px;
  line-height: 40%;
  font-weight: bold;
}

.login-title > p {
  font-size: 24px;
  font-weight: 400;
}

.login-flex {
  display: flex;
  justify-content: center;
  width: 100%;
  gap: 100px;
}

.login-left {
  flex: 1;
}

.login-account {
  flex: 3;
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.login-account h6 {
  font-size: 20px;
  color: #fff;
  font-weight: bold;
}

.login-remember {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.login-remember > a {
  color: #fff;
  font-size: 16px;
  font-weight: 700;
  padding: 10px 24px;
  transition: 0.4s ease-in-out;
}

.login-remember > a:hover {
  border-radius: 4px;
  background: rgba(17, 18, 21, 0.5);
  box-shadow: 0px 1px 3px 1px rgba(0, 0, 0, 0.15),
    0px 1px 2px rgba(0, 0, 0, 0.3);
}

.login-checkbox {
  display: flex;
  align-items: center;
  gap: 25px;
}

.login-checkbox > input[type="checkbox"] {
  position: relative;
  appearance: none;
  width: 18px;
  height: 18px;
  border: 2px solid #fff;
  border-radius: 2px;
  cursor: pointer;
}

.login-checkbox > input[type="checkbox"]:checked {
  transition: 1s ease-in-out all;
}

.login-checkbox > input[type="checkbox"]:checked::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #fff;
}

.login-checkbox > input[type="checkbox"]:checked::before {
  content: "";
  position: absolute;
  z-index: 1;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: url("../../../assets/img/check.png");
  background-position: center;
  background-repeat: no-repeat;
}

.login-checkbox > span {
  color: #fff;
  font-size: 16px;
}

.login-accept > button {
  background: rgba(17, 18, 21, 0.5);
  width: 254px;
  height: 44px;
  color: #eaeaea;
  font-size: 16px;
  font-weight: bold;
  border-radius: 4px;
  transition: 0.4s ease-in-out all;
}

.login-accept > button:hover {
  background: rgba(17, 18, 21, 0.5);
  filter: drop-shadow(0px 1px rgba(0, 0, 0, 0.3));
}

.already-account {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.already-account > span {
  color: #fff;
  font-size: 18px;
}

.already-account > span > a {
  color: #fff;
  font-size: 16px;
  font-weight: 700;
  padding: 10px 24px;
  transition: 0.4s ease-in-out;
}

.already-account > span > a:hover {
  border-radius: 4px;
  background: rgba(17, 18, 21, 0.5);
  box-shadow: 0px 1px 3px 1px rgba(0, 0, 0, 0.15),
    0px 1px 2px rgba(0, 0, 0, 0.3);
}

@media only screen and (max-width: 780px) {
  .login-flex {
    gap: 20px;
  }

  .login-left {
    justify-content: flex-start;
  }
}

/* Error için gerekli style */
.login-account.error label.username {
  background: #fff;
  opacity: 1;
}

.login-account.error label.username input {
  color: rgba(238, 98, 67, 1);
}

.login-account.error label.username > h6 {
  color: #ee6243;
}

.login-account.error label.username svg path {
  fill: #ee6243;
}

.login-account.error label.username input {
  border: 2px solid #ee6243;
  border-radius: 4px;
  background: url("../../../assets/Svg/error.svg");
  background-repeat: no-repeat;
  background-position: 98% center;
}

.login-account.error label.email {
  background: #fff;
  opacity: 1;
}

.login-account.error label.email input {
  color: rgba(238, 98, 67, 1);
}

.login-account.error label.email > h6 {
  color: #ee6243;
}

.login-account.error label.email svg path {
  fill: #ee6243;
}

.login-account.error label.email input {
  border: 2px solid #ee6243;
  border-radius: 4px;
  background: url("../../../assets/Svg/error.svg");
  background-repeat: no-repeat;
  background-position: 98% center;
}

.login-account.error label.first-name {
  background: #fff;
  opacity: 1;
}

.login-account.error label.first-name input {
  color: rgba(238, 98, 67, 1);
}

.login-account.error label.first-name > h6 {
  color: #ee6243;
}

.login-account.error label.first-name svg path {
  fill: #ee6243;
}

.login-account.error label.first-name input {
  border: 2px solid #ee6243;
  border-radius: 4px;
  background: url("../../../assets/Svg/error.svg");
  background-repeat: no-repeat;
  background-position: 98% center;
}

.login-account.error label.surname {
  background: #fff;
  opacity: 1;
}

.login-account.error label.surname input {
  color: rgba(238, 98, 67, 1);
}

.login-account.error label.surname > h6 {
  color: #ee6243;
}

.login-account.error label.surname svg path {
  fill: #ee6243;
}

.login-account.error label.surname input {
  border: 2px solid #ee6243;
  border-radius: 4px;
  background: url("../../../assets/Svg/error.svg");
  background-repeat: no-repeat;
  background-position: 98% center;
}

.wrong-password,
.wrong-username {
  display: none;
}

.wrong-password.error {
  display: block;
  padding-left: 15px;
  color: #fff !important;
  font-size: 12px;
}

.wrong-username.error {
  display: block;
  padding-left: 15px;
  color: #fff !important;
  font-size: 12px;
}

.login-account.error label.password {
  background: #fff;
  opacity: 1;
}

.login-account.error label.password input {
  color: rgba(238, 98, 67, 1);
}

.login-account.error label.password > h6 {
  color: #ee6243;
}

.login-account.error label.password svg path {
  fill: #ee6243;
}

.login-account.error label.password input {
  border: 2px solid #ee6243;
  border-radius: 4px;
  background: url("../../../assets/Svg/error.svg");
  background-repeat: no-repeat;
  background-position: 98% center;
}

/* Error için gerekli style */
