.loading {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  gap: 50px;
}
.loading-logo {
  width: 100%;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
}
.loading-container {
  gap: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  z-index: 1;
}
.loading-container h3 {
  font-size: 24px;
  color: white;
  font-weight: 400;
  text-align: center;
}

.loading-container p {
  font-size: 18px;
  font-weight: 400;
  color: white;
  text-align: center;
}
