.social {
  width: 100%;
  position: relative;
  z-index: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 56px;
}

@media only screen and (max-width: 960px) {
  .social {
    max-width: 700px;
    width: 100%;
    margin: 0 auto;
    position: relative;
  }
  .social-title {
    margin-bottom: 30px;
  }
}

.social-title h1 {
  font-size: 32px;
  font-weight: 700;
  text-align: center;
  color: white;
}

.social-container-text {
  max-width: 85%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 25px;
}

.social-container-text p {
  color: white;
  font-size: 14px;
  font-weight: 700;
  line-height: 20px;
}
.social-container-text > a > p {
  text-decoration: none;
  background: #11121580;
  width: 240px;
  height: 40px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
}

.social-container-text a > p:hover {
  background: #11121599 !important;
}
