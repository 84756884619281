.webthree-loading {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1;
  width: 100%;
  height: 100vh;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: rgba(0, 0, 0, 0.4);
  gap: 15px;
}

.webthree-loading .loading-circle {
  width: 75px;
  height: 75px;
  border-radius: 50%;
  border: 10px solid #fff;
  border-top: 10px solid #50bfde;
  animation: spin 2s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.webthree-loading h1 {
  font-size: 20px;
  color: #fff;
}
