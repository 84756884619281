.metamask-email {
  position: relative;
  z-index: 1;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 56px;
}

.metamask-title {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 24px;
  color: #fff;
}

.metamask-title > h3 {
  font-size: 32px;
  line-height: 40%;
  font-weight: bold;
}

.metamask-title > p {
  font-size: 24px;
  font-weight: 400;
}

.metamask-flex {
  display: flex;
  justify-content: center;
  width: 100%;
  gap: 100px;
}

.metamask-left {
  flex: 1;
}

.metamask-account {
  flex: 3;
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.metamask-account h6 {
  font-size: 20px;
  color: #fff;
  font-weight: bold;
}

.metamask-remember {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.metamask-remember > a {
  color: #fff;
  font-size: 16px;
  font-weight: 700;
  padding: 10px 24px;
  transition: 0.4s ease-in-out;
}

.metamask-remember > a:hover {
  border-radius: 4px;
  background: rgba(17, 18, 21, 0.5);
  box-shadow: 0px 1px 3px 1px rgba(0, 0, 0, 0.15),
    0px 1px 2px rgba(0, 0, 0, 0.3);
}

.metamask-checkbox {
  display: flex;
  align-items: center;
  gap: 25px;
}

.metamask-checkbox > input[type="checkbox"] {
  position: relative;
  appearance: none;
  width: 18px;
  height: 18px;
  border: 2px solid #fff;
  border-radius: 2px;
  cursor: pointer;
}

.metamask-checkbox > input[type="checkbox"]:checked {
  transition: 1s ease-in-out all;
}

.metamask-checkbox > input[type="checkbox"]:checked::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #fff;
}

.metamask-checkbox > input[type="checkbox"]:checked::before {
  content: "";
  position: absolute;
  z-index: 1;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: url("../../../assets/img/check.png");
  background-position: center;
  background-repeat: no-repeat;
}

.metamask-checkbox > span {
  color: #fff;
  font-size: 16px;
}

.metamask-accept > button {
  background: rgba(17, 18, 21, 0.5);
  width: 254px;
  height: 44px;
  color: #eaeaea;
  font-size: 16px;
  font-weight: bold;
  border-radius: 4px;
  transition: 0.4s ease-in-out all;
}

.metamask-accept > button:hover {
  background: rgba(17, 18, 21, 0.5);
  filter: drop-shadow(0px 1px rgba(0, 0, 0, 0.3));
}

.already-account {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.already-account > span {
  color: #fff;
  font-size: 18px;
}

.already-account > span > a {
  color: #fff;
  font-size: 16px;
  font-weight: 700;
  padding: 10px 24px;
  transition: 0.4s ease-in-out;
}

.already-account > span > a:hover {
  border-radius: 4px;
  background: rgba(17, 18, 21, 0.5);
  box-shadow: 0px 1px 3px 1px rgba(0, 0, 0, 0.15),
    0px 1px 2px rgba(0, 0, 0, 0.3);
}

@media only screen and (max-width: 780px) {
  .metamask-flex {
    gap: 20px;
  }

  .metamask-left {
    justify-content: flex-start;
  }
}

/* Error için gerekli style */
.metamask-account.error label.code {
  background: #fff;
  opacity: 1;
}

.metamask-account.error label.code > h6 {
  color: #ee6243;
}

.metamask-account.error label.code svg path {
  fill: #ee6243;
}

.metamask-account.error label.code input {
  border: 2px solid #ee6243;
  color: #ee6243;
  border-radius: 4px;
  background: url("../../../assets/Svg/error.svg");
  background-repeat: no-repeat;
  background-position: 98% center;
}

.wrong-code {
  display: none;
}

.wrong-code.error {
  display: block;
  padding-left: 15px;
  color: #fff !important;
  font-size: 12px;
}
