.terms {
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
}

.social {
  width: 100%;
  position: relative;
  z-index: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  gap: 56px;
  background: rgba(255, 255, 255, 0.1);
  backdrop-filter: blur(25px);
  box-shadow: 0px 4px 20px 0 rgba(0, 0, 0, 0.2);
  padding: 25px;
}

.social-title {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 24px;
}

@media only screen and (max-width: 960px) {
  .social {
    max-width: 700px !important;
    width: 100% !important;
    margin: 0 auto;
    position: relative !important;
  }
  .social-title {
    margin-bottom: 30px;
  }
}

.social-title h1 {
  font-size: 32px;
  font-weight: 700;
  text-align: center;
  color: white;
}

.social-title h6 {
  font-size: 24px;
  font-weight: 400;
  text-align: center;
  color: white;
}

.social-container {
  max-width: 954px;
  width: 100%;
  height: 320px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 56px;
}

.scroll::-webkit-scrollbar {
  width: 10px;
  height: 25px;
}

.scroll::-webkit-scrollbar-track {
  border-radius: 100px;
}

.scroll::-webkit-scrollbar-thumb {
  background-color: white;
  border-radius: 100px;
}

.scroll {
  height: 460px;
  overflow-y: scroll;
  scrollbar-color: #d4aa70 #e4e4e4;
  scrollbar-width: thin;
  color: white;
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  padding: 5px;
}

.scroll h5 {
  font-size: 16px;
  font-weight: 600;
}

.scroll-padding {
  width: 100%;
  padding-right: 25px;
}

.scroll p {
  font-size: 14px;
  font-weight: 400 !important;
  padding-right: 15px;
}

.social-container .terms-button {
  text-decoration: none;
  background: #11121580;
  width: 240px;
  height: 40px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  color: white;
  padding: 15px;
  cursor: pointer;
}

.social-container .terms-button.disabled {
  opacity: 0.5;
  pointer-events: none;
}

.social-container p > div > a {
  text-decoration: none;
  color: white;
}

.social-containerp > a > p:hover {
  background: #11121599 !important;
}

.accept {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 40px;
}

.accept span {
  font-size: 16px;
  font-weight: 700;
}

.accept input[type="checkbox"] {
  position: relative;
  appearance: none;
  width: 24px;
  height: 24px;
  background-color: #fff;
  border: 1px solid rgba(68, 68, 68, 0.2);
  border-radius: 6px;
  transition: 0.4s ease-in-out all;
  cursor: pointer;
}

.accept input[type="checkbox"]:checked {
  background: rgb(125, 125, 125);
}

.accept input[type="checkbox"]:checked::before {
  content: "";
  width: 100%;
  height: 100%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 99;
  background: url("../../assets/images/checkbox.png");
  background-repeat: no-repeat;
  background-position: center;
  background-size: 50%;
}
