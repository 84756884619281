.slider-download-app {
  display: none;
}

@media screen and (max-width: 767px) {
  .slider-download-app {
    position: absolute;
    z-index: 9999;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all 0.5s ease-in-out;
  }

  .slider-download-app.active {
    position: absolute;
    z-index: 9999;
    top: 0px;
    left: 0px;
    width: 460px;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all 0.5s ease-in-out;
  }

  .slider-download-app.active .slider-download-line {
    display: none;
  }

  .slider-download-line {
    width: 5px;
    height: 135px;
    border-radius: 5px;
    background: #fff;
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1);
  }

  .slider-download-app .slider-download-content {
    /* width: 100%; */
    height: 100%;
    background: #fff;
    /* margin-right: 25px; */
    padding: 15px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 20px;
  }

  .slider-download-app .slider-download-content .slider-gray-line {
    width: 72px;
    height: 4px;
    background: rgba(68, 68, 68, 0.1);
    border-radius: 4px;
  }

  .slider-download-app .slider-download-content > h1 {
    font-size: 20px !important;
    white-space: nowrap;
    color: rgba(17, 18, 21, 1);
    font-weight: 700;
  }
  .slider-download-app .slider-download-content > p {
    color: #a4a7b1;
    font-size: 16px;
    font-weight: 400;
    line-height: 2;
    margin: 0;
    text-align: center;
  }

  .slider-download-app .slider-download-content .download-app-content-line {
    width: 400px;
    height: 1px;
    background: rgba(68, 68, 68, 0.3);
    border-radius: 1px;
  }

  .slider-download-app .slider-download-content .download-app-button {
    cursor: pointer;
    padding: 10px 24px;
    background: black;
    border-radius: 4px;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 700;
    gap: 10px;
  }
}

@media screen and (max-width: 576px) {
  .slider-download-app {
    position: absolute;
    z-index: 9999;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all 0.5s ease-in-out;
  }

  .slider-download-app.active {
    position: absolute;
    z-index: 9999;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all 0.5s ease-in-out;
  }

  .slider-download-app.active .slider-download-line {
    display: none;
  }

  .slider-download-line {
    width: 5px;
    height: 135px;
    border-radius: 5px;
    background: #fff;
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1);
  }

  .slider-download-app .slider-download-content {
    /* width: calc(100% + 25px); */
    height: 100%;
    background: #fff;
    /* margin-right: 25px; */
    padding: 15px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 20px;
  }

  .slider-download-app .slider-download-content .slider-gray-line {
    width: 72px;
    height: 4px;
    background: rgba(68, 68, 68, 0.1);
    border-radius: 4px;
  }

  .slider-download-app .slider-download-content > h1 {
    font-size: 20px !important;
    width: 280px;
    white-space: pre-wrap;
    color: rgba(17, 18, 21, 1);
    font-weight: 700;
    text-align: center;
  }

  .slider-download-app .slider-download-content .download-app-content-line {
    width: 300px;
    height: 1px;
    background: rgba(68, 68, 68, 0.3);
    border-radius: 1px;
  }

  .slider-download-app .slider-download-content .download-app-button {
    cursor: pointer;
    padding: 10px 24px;
    background: black;
    border-radius: 4px;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 700;
    gap: 10px;
  }
}
