.background {
  position: relative;
  width: 100%;
  height: 100vh;
  background: linear-gradient(
    to left top,
    #0afff0 0%,
    #80b2ec 36.98%,
    #c565d5 60.42%,
    #f54fb3 100%
  );
}

.background::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  max-width: 500px;
  width: 100%;
  height: 500px;
  background: #ffbd47;
  filter: blur(160px);
  transform: rotate(57.19deg);
  animation-name: bg-animation;
  animation-duration: 4s;
  animation-delay: 2s;
}

@keyframes bg-animation {
  0% {
    position: absolute;
    left: 0;
    width: 500px;
    height: 500;
    bottom: 0;
    background: #ffbd47;
    filter: blur(160px);
    transform: rotate(57.19deg);
  }

  50% {
    position: absolute;
    left: 500px;
    width: 500px;
    height: 500px;
    bottom: 500px;

    background: #ffbd47;
    filter: blur(160px);
    transform: rotate(90.19deg);
  }

  100% {
    position: absolute;
    left: 0;
    width: 500px;
    height: 500;
    bottom: 0;

    background: #ffbd47;
    filter: blur(160px);
    transform: rotate(57.19deg);
  }
}

@media (max-width: 576px) {
  .background {
    position: relative;
    width: 100%;
    height: 100vh;
    background: linear-gradient(
      to left top,
      #0afff0 0%,
      #80b2ec 36.98%,
      #c565d5 60.42%,
      #f54fb3 100%
    );
  }

  .background::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    max-width: 300px;
    width: 100%;
    height: 300px;
    background: #ffbd47;
    filter: blur(160px);
    transform: rotate(57.19deg);
    animation-name: bg-animation;
    animation-duration: 4s;
    animation-delay: 2s;
  }
}
