.auth-cards {
  position: relative;
  width: 15rem;
  height: 15rem;
  /* padding: 15px; */
  gap: 20px;
  background: rgba(217, 217, 217, 0.12);
  box-shadow: rgba(75, 75, 75, 0.35) 0px 5px 6px;
  backdrop-filter: blur(2px);
  border-radius: 8px;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  transition: 0.4s ease-in-out all;
}

.auth-cards::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  border-radius: 8px;
  width: 100%;
  height: 100%;
  z-index: -4;
  background: none;
}

.auth-cards:hover {
  background: black;
  border: 3px solid;
  border-radius: 8px;
}

.auth-cards:hover::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  border-radius: 8px;
  width: 100%;
  height: 100%;
  z-index: -1;
  background: black;
}

.auth-cards:hover::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  border-radius: 8px;
  width: 100%;
  height: 100%;
  z-index: -1;
  background: linear-gradient(45deg, rgb(6, 175, 201), rgb(201, 17, 217));
  transform: scale(1.02);
  /* background: none; */
}

.auth-cards > .card-svg {
  transition: 0.4s ease-in-out all;
}

.auth-cards:hover > .card-svg {
  transform: scale(1.2) !important;
}

.auth-cards:hover .auth-title {
  font-weight: 800;
  background: linear-gradient(
    to right,
    #0afff0 0%,
    #80b2ec 36.98%,
    #c565d5 60.42%,
    #f54fb3 100%
  );
  background-clip: text;
  -webkit-text-fill-color: transparent;
  transition: 0.4s ease-in-out all;
  transform: translateY(15px);
}

.auth-title {
  font-weight: bold;
  font-size: 20px;
  line-height: 26px;
  color: #fff;
}

a {
  text-decoration: none;
}
