.container {
  max-width: 1200px;
  width: 100%;
  margin: 0 auto;
  position: relative;
}

/* @media only screen and (max-width: 780px) {
  .container {
    max-width: 628px;
    width: 100%;
    margin: 0 auto;
    position: relative;
  }
} */
