label {
  position: relative;
  display: inline-block;
  border-radius: 4px;
  width: 100%;
  height: 56px;
  background: rgba(17, 18, 21, 0.1);
}

label > svg {
  position: absolute;
  left: 10px;
  top: 50%;
  transform: translate(5px, -50%);
}

label > h6 {
  position: absolute;
  top: 50%;
  left: 15px;
  transform: translate(0px, -50%);
  font-size: 20px !important;
  font-weight: 400 !important;
  transition: 0.5s ease-in-out all;
  pointer-events: none;
}

label input {
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  background: none;
  outline: none;
  border: none;
  padding-left: 15px;
  color: #fff;
  padding-top: 15px;
}

.title-padding {
  padding-left: 30px;
}

label input:focus ~ h6,
label input:valid ~ h6 {
  font-size: 14px !important;
  transform: translateY(-20px);
}
