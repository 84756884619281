.progress-bar {
  width: 100%;
  height: 10px;
  background-color: transparent;
  position: absolute;
  bottom: 3%;
}

.progress {
  height: 100%;
  background-color: #fbfdff;
  position: relative;
  top: 0;
  left: 0;
  transition: width 0.1s ease-in-out;
  margin-top: 20px;
}

.progress-text {
  position: absolute;
  top: -20px;
  font-size: 16px;
  font-weight: 600;
  right: 0;
  line-height: 20px;
  padding: 0 10px;
  font-size: 12px;
  color: #fff;
}
