.register-hfull {
  width: 100%;
}

.register {
  position: relative;
  z-index: 1;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 36px;
}

.input-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
}

.input-flex {
  width: 100%;
  display: flex;
  gap: 15px;
}

.register-title {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 24px;
  color: #fff;
}

.register-title > h3 {
  font-size: 32px;
  line-height: 40%;
  font-weight: bold;
}

.register-title > p {
  font-size: 24px;
  font-weight: 400;
}

.register-inputs {
  display: flex;
  gap: 32px;
  max-width: 932px;
  width: 100%;
  /* margin-top: 56px; */
}

.register-inputs .information {
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.register-inputs .information span {
  color: #fff;
  font-size: 12px;
  padding-left: 16px;
}

.register-inputs .information h6 {
  font-size: 20px;
  color: #fff;
  font-weight: bold;
}

.register-inputs .account {
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.register-inputs .account h6 {
  font-size: 20px;
  color: #fff;
  font-weight: bold;
}

button {
  background: none;
  outline: none;
  border: none;
  cursor: pointer;
}

button.disabled {
  width: 118px;
  height: 44px;
  padding: 10px 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(255, 255, 255, 0.12);
  color: #fff;
  font-size: 16px;
  font-weight: 700;
  border-radius: 4px;
  pointer-events: none;
  transition: 1s ease-in-out;
}

button.continue {
  width: 118px;
  height: 44px;
  padding: 10px 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(17, 18, 21, 0.5);
  color: #fff;
  font-size: 16px;
  font-weight: 700;
  border-radius: 4px;
  transition: 1s ease-in-out;
}

.already-account {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.already-account > span {
  color: #fff;
  font-size: 18px;
}

.already-account > span > a {
  color: #fff;
  font-size: 16px;
  font-weight: 700;
  padding: 10px 24px;
  transition: 0.4s ease-in-out;
}

.already-account > span > a:hover {
  border-radius: 4px;
  background: rgba(17, 18, 21, 0.5);
  box-shadow: 0px 1px 3px 1px rgba(0, 0, 0, 0.15),
    0px 1px 2px rgba(0, 0, 0, 0.3);
}

.customClass {
  width: 100%;
  font-size: 12px;
  color: red;
  font-weight: 700;
  text-shadow: rgba(17, 18, 21, 0.5);
}

.register.error label.email,
.register.error label.username,
.register.error label.password {
  background: #fff;
  opacity: 1;
}

.register.error label.email > h6,
.register.error label.username > h6,
.register.error label.password > h6 {
  color: #ee6243;
}

.register.error label.email svg path {
  fill: #ee6243;
}

.register.error label.password svg path {
  fill: #ee6243;
}

.register.error label.email input {
  border: 2px solid #ee6243;
  color: #ee6243;
  border-radius: 4px;
  background: url("/src/assets/Svg/error.svg");
  background-repeat: no-repeat;
  background-position: 98% center;
}

.register.error label.username input {
  color: #ee6243;
  border: 2px solid #ee6243;
  border-radius: 4px;
  background: url("/src/assets/Svg/error.svg");
  background-repeat: no-repeat;
  background-position: 98% center;
}

.register.error label.password input {
  color: #ee6243;
  border: 2px solid #ee6243;
  border-radius: 4px;
  background: url("/src/assets/Svg/error.svg");
  background-repeat: no-repeat;
  background-position: 98% center;
}

.wrong-password,
.wrong-email,
.wrong-username {
  display: none;
}

.wrong-password.error {
  display: block;
  padding-left: 15px;
  color: #fff;
  font-size: 12px;
}

.wrong-email.error {
  display: block;
  padding-left: 15px;
  color: #fff;
  font-size: 12px;
}

.wrong-username.error {
  display: block;
  padding-left: 15px;
  color: #fff;
  font-size: 12px;
}

.account.error label.password {
  background: #fff;
  opacity: 1;
}

.account.error label.password > h6 {
  color: #ee6243;
}

.account.error label.password svg path {
  fill: #ee6243;
}

.account.error label.password input {
  border: 2px solid #ee6243;
  border-radius: 4px;
  background: url("/src/assets/Svg/error.svg");
  background-repeat: no-repeat;
  background-position: 98% center;
}

@media only screen and (max-width: 768px) {
  .register-inputs {
    display: flex;
    flex-direction: column;
  }
}
