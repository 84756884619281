.login {
  width: 100%;
  position: relative;
  z-index: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 56px;
}

.login-title {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  color: white;
  gap: 4px;
}

.login-title h1 {
  font-size: 45px;
  font-weight: 700;
}

.login-title p {
  font-size: 20px;
  font-weight: 400;
}

.login-container {
  width: 100%;
  display: grid;
  grid-gap: 10px;
  justify-items: center;
  align-content: center;
  grid-template-columns: repeat(auto-fill, minmax(240px, 1fr));
  gap: 56px;
}

.login-lower {
  color: white;
}

.login-metamask {
  position: relative;
}

.login-frame {
  position: absolute;
  z-index: 2;
  top: -5px;
  right: -5px;
}

.login-lower h6 {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  font-size: 18px;
  font-weight: 400;
}

.login-lower a {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 130px;
  height: 30px;
  color: white;
  text-decoration: none;
  text-align: center;
  font-weight: 700;
}

.login-lower a:hover {
  border-radius: 5px;
}

.login-social,
.login-metamask {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
  gap: 25px;
}

.login-social > div + a {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 200px;
  height: 30px;
  transition: 0.4s ease-in-out;
}

.login-social > div + a:hover {
  border-radius: 5px !important;
  background: #11121580 !important;
}

.login-social p {
  width: 200px;
  height: 30px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  font-weight: 400 !important;
}

.login-metamask > a {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 200px;
  height: 30px;
  transition: 0.4s ease-in-out;
}

.login-metamask p {
  text-align: center;
  color: white;
  font-weight: 400 !important;
}

.login-metamask a:hover {
  border-radius: 5px !important;
  background: #11121580 !important;
}

.already-account {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.already-account > span {
  color: #fff;
  font-size: 18px;
}

.already-account > span > a {
  color: #fff;
  font-size: 16px;
  font-weight: 700;
  padding: 10px 24px;
  transition: 0.4s ease-in-out;
}

.already-account > span > a:hover {
  border-radius: 4px;
  background: rgba(17, 18, 21, 0.5);
  box-shadow: 0px 1px 3px 1px rgba(0, 0, 0, 0.15),
    0px 1px 2px rgba(0, 0, 0, 0.3);
}

@media only screen and (max-width: 780px) {
  .register-inputs {
    display: flex;
    flex-direction: column;
  }
}
